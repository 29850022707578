.lessonCard {
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  border-radius: 10px;
  background-size: cover;
  background-position: center 90%;
  // overflow: hidden;
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  z-index: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  &.mobile {
    grid-template-columns: 1fr;
    position: relative;

    .desc {
      font-size: 1em;
    }
  }

  &__completed {
    opacity: 0.4;
  }

  &__locked {
    pointer-events: none;
    opacity: 0.5;
  }

  &__container {
    min-height: 100px;
    max-height: 300px;
    padding: 10px;
  }

  .hidden {
    display: none;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;

    text-shadow:
      -3px -3px 0 #fff,
      3px -3px 0 #fff,
      -3px 3px 0 #fff,
      3px 3px 0 #fff;

    .title {
      margin: 20px 0 0 0;
      font-size: 22px;
      font-weight: 500;
    }

    .desc {
      font-size: 1.4em;
      line-height: 1.2;
    }

    .coverWrapper {
      padding: 10px;
    }

    .cover {
      object-fit: cover;
      height: 100px;
      width: 100px;
      border-radius: 5px;
    }

    &__mobile {
      grid-template-columns: 1fr 2.5fr;
      grid-gap: 5px;
      align-items: center;

      .title {
        line-height: 1.1;
        font-size: 20px;
        margin: 0;
      }

      .desc {
        font-size: 16px;
        margin: 5px 0;
      }

      .coverWrapper {
        padding: unset;
      }

      .cover {
        width: 40px;
        height: 40px;

        border: none;
      }
    }
  }

  .progress {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .stars {
    //position: absolute;
    //left: 20px;
    //bottom: 5px;
    font-size: 14px;
  }
}
