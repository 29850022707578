.storySent {
  &__text {
    font-size: 19px;
    margin-right: 5px;
    line-height: 1.4;

    &:hover {
      cursor: pointer;

      text-decoration: underline;
      text-decoration-color: lightgrey;
      text-decoration-style: dotted;
    }
  }

  &__textDict {
    line-height: 1.6;
    font-weight: 500;
  }

  &__textWrap {
    margin-left: 30px;
  }

  &__textActive {
    color: black;
    border-bottom: 1px dashed grey;

    &:hover {
      text-decoration: none;
    }
  }

  &__translate {
    color: grey;
    font-weight: normal;
  }

  .dict {

    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px ;
      padding: 5px;
      padding-top: 10px;
    }
  }
}

.word {

  &_active {
    border-bottom: 1px solid black;
    //text-decoration: underline;
    //text-decoration-color: grey;
    //text-decoration-style: dotted;
  }
}

.wrap {
  display: block;
  margin-bottom: 3px;

}

