.videoTask {
  display: grid;

  ::cue {
    // background-color:rgba(0, 0, 0, 0.8);
    // color: rgb(255, 200, 0);
    // padding-bottom: 2em;
  }
  ::cue(u) {
    text-decoration: unset;
   color: rgb(255, 200, 0);
  }

  .player {
    margin: 0 -50px;
    position: relative;

    &:hover {
      cursor: pointer;
    }
   // height: 100%;

    .btnClose {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .btnPlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%) translateY(-50%);
    color: rgba(255, 255, 255, 0.8);
    font-size: 48px;
  }

  &_mobile {
     //height: calc(100vh - 250px);
    .player {
      margin: -20px;
      height: 100%;
      //  margin-bottom: 0;
    }
  }

  .skeleton {
    height: 600px;
    overflow: hidden;
  }


  .transcript {
    overflow-y: scroll;
  }
}