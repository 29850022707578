.videoSelect {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &_mobile {
    grid-template-columns: unset;
  }

  .clip {
    border: 3px solid transparent;

    &:hover {
     //  border: 3px solid rgb(0, 0, 0);
      //background-color: black;
    }
  }
}