.storyTask {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 10px 0;
  // height: calc(100% - 60px);

  &__top {
    z-index: 1;
    position: sticky;
    top: 0;
    padding: 10px 0;
    background: white;
  }

  .segmented {
    margin-bottom: 20px;
  }

  .videoPlayer {
    // height: unset;
    max-height: 360px;
    margin-bottom: 15px;
  }

  .image {
    float: right;
    margin: 20px 0 20px 20px;
    z-index: 1;

    &__img {
      max-width: 400px;
      max-height: 300px;
    }


    &_center {
      float: unset;
      text-align: center;
    }
  }

  .mask {
    background: unset;
  }

  .steps {
    margin-bottom: 20px;
  }

  .btn_next {
    background-color: #7cb305;
    animation: greenPulse 1s;

    &:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
      animation: unset;
    }
  }

  &__text {
    line-height: 1.4;
    font-size: 18px;
  }

  &__title {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 500;
  }

  .btns {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  .placeholder {
    height: 60px;
  }

  &_mobile {
    .videoPlayer {
      height: unset;
    }

    .image {
      float: unset;
      margin-left: unset;
      max-width: 100%;
    }
  }
}

.tooltip {
  .dict {
    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px;
      padding: 5px;
      padding-top: 10px;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgb(35, 152, 35);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
    }
  }
}
