.conversation {


  .imgContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    .picture {
      width: fit-content;
      max-height: 50%;
    }

  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;

    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -webkit-tap-highlight-color:rgba(0,0,0,0);

    .btn__repeat {
      position: absolute;
      left: 0;
      top: 50px;
    }

    .recorder {
      position: absolute;
      top: 50px;
      user-select:none;

      -webkit-touch-callout:none;
      -webkit-user-select:none;
      -moz-user-select:none;
      -ms-user-select:none;
      -webkit-tap-highlight-color:rgba(0,0,0,0);

      &__disabled {
        pointer-events: none;
      }
    }

    .audioViz {
      height: 180px;
      visibility: hidden;

      &__isPlaying {
        visibility: visible;
      }
    }


  }
}
