.speechLessonTask {
  margin: 15px 0;
  min-height: 120px;


  @media(max-height: 700px) {
    .children {
      max-height: 150px !important;
    }

    .content {
      grid-gap: 5px !important;
    }
  }

  @media(max-height: 600px) {
    .children {
      max-height: 100px !important;
    }
  }

  &__inActive {
    opacity: 0.4;
  }

  .content {
    display: grid;
    justify-items: center;
    align-items: center;
    justify-self: center;
    height: 100%;
    width: 100%;

    &_collectTask {
      // grid-template-rows: unset;
    }

    &_autoHeight {
      grid-template-rows: auto auto auto;
    }

    .children {
      display: flex;
      justify-content: center;
      max-height: 200px;
      width: 100%;

      img {
        min-height: 30px;
        width: 100%;
      }
    }

    .slate {
      width: 100%;

      &_spellerCollect {
        max-width: 350px;
      }

      &_alignCenter {
        text-align: center;

        .textArea {
          padding-left: 10px;
          padding-right: 0 !important;
        }
      }

      .slate_wrapper {
        width: 100%;
        position: relative;
        padding-left: 10px;
        padding-right: 15px;
        font-family: "Montserrat", Arial, Helvetica, sans-serif;

        .textArea {
          outline: none;
          border-bottom: 1px dashed lightgrey;
          padding-right: 15px;
          font-weight: 500;
          line-height: 1.4;

         .voiceTags {
           display: flex;
           flex-wrap: wrap;
          justify-content: center;

           .voiceTag {
             font-size: 18px;
             margin-right: 5px;
             color: transparent;

             &__hint {
               color: #333333;
             }

             &__merged {
               margin-right: unset;
             }

             &__active {
               border-bottom: 2px solid grey;
             }

             &__completed {
               color: black;
             }
           }
         }
        }

        .btn__clear {
          position: absolute;
          bottom: 5px;
          right: 0;
        }
      }

      .translate {
        font-size: 18px;
        margin: 10px 0;
        padding-left: 10px;
        min-height: 22px;
      }
    }

    .answer {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      min-height: 44px;

      margin: 0;
    }

    .optionsWrapper {
      min-height: 90px;

      .options {
        display: flex;
        grid-gap: 5px;
        flex-wrap: wrap;
        justify-content: center;

        &__mobile {
          grid-gap: 10px;
        }
      }
    }

    .tag {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 21px;
      line-height: 1.4;
      margin: 1.5px;
      padding: 2px 5px;
      background: #f6f6f6;
      border-radius: 5px;

      &.letter {
        //font-size: 32px;
        padding: 2px 10px;
        font-weight: 500;
      }

      &__mobile {
        font-size: 26px;
      }

      &__mobile.letter {
        font-size: 32px;
      }


      &__wrong {
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-color: red;
      }

      &__merged {
        margin-left: -2px;
        padding-left: 0;
      }

      &__selected {
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: gray;
      }
    }

    .bottom {
      display: grid;
      justify-content: center;
      align-items: center;

      .icon_completed {
        font-size: 64px;
        color: #7cb305;
      }

      .select {
        justify-content: center;
        margin: 0 -10px;
      }
    }
  }

  .pictures {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-auto-rows: auto;
    //grid-auto-flow: dense;
    gap: 6px;

    &_mobile {
      grid-template-columns: repeat(2, 1fr);
    }

    .picture {
      padding: 20px;

      .img {
        max-height: 160px;
      }
    }
  }

  .record {
    background-color: #930202;

    &:hover {
      background-color: #d00303 !important;
    }

    &[data-recording="true"] {
      animation: pulse 1s infinite;
    }
  }

  .panel {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #eef6ff;
    display: flex;
    justify-content: center;

    &__content {
      width: 100%;
      max-width: 1100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;

      @media (max-width: 600px) {
        padding: 5px 10px;
      }

      @media (min-width: 1100px) {
        padding: 5px 10px;
      }
    }

    .btn_play {
      // font-size: 32px;
      color: dodgerblue;

      &:hover {
        transform: scale(1.1);
      }
    }

    .btn_next {
      background-color: #7cb305;

      &:hover {
        background-color: #92cb0d;
      }

      &:disabled {
        background-color: lightgrey;
      }
    }
  }

  .btn_green {
    border-color: #239823 !important;
    animation: greenPulse 1s;
    color: green;

    &:hover {
      color: green !important;
    }
  }

  .btn_red {
    border-color: #810000 !important;
    animation: pulse 1s;
    color: #810000;

    &:hover {
      color: #810000 !important;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgb(35, 152, 35);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
    }
  }
}
