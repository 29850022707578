.story {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;  ;
  font-weight: 400;
  position: relative;
   margin: 10px 0;

  &__top {
    z-index: 1;
    position: sticky;
    top: 0;
    padding: 10px 0;
    background: white;
  }

  .steps {
    margin-bottom: 20px;
  }

  .btn__close {
    position: absolute;
    right: -40px;
    top: -20px;
    color: grey;
    @media (max-width: 600px) {
      right: -10px;
    }
  }

  &__text {
    margin: 20px 0;
    line-height: 1.4;
    font-size: 18px;
  }

  &__title {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 500;
  }

  .btns {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }

  .placeholder {
    height: 60px;
  }
}

.tooltip {
  .dict {

    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px ;
      padding: 5px;
      padding-top: 10px;
    }
  }
}