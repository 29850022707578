.dialogTaskWriting {
  margin-right: 10px;

  .slate {
    width: 100%;
    position: relative;

    .slate_wrapper {
      width: 100%;
      position: relative;
      padding: 5px 35px 5px 10px;
      color: white;
      background: cornflowerblue;
      border-radius: 10px;
      border-bottom-right-radius: 0;

      &__left {
        background: rgba(70, 0, 0, 0.45);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;

      }

      &__completed {
        padding-right: 10px;
      }

      .textArea {
        outline: none;
       //  border-bottom: 1px dashed lightgrey;
        &.text {
          font-family: "Montserrat", Arial, Helvetica, sans-serif;
          font-size: 21px;
        }
      }

      .btnHint {
        position: absolute;
        bottom: 3px;
        right: 0;
        color: white;
      }
    }


    .translate {
      //font-size: 18px;
    }
  }

  .bulb {
    position: absolute;
    right: -35px;
    color: grey;

    &__right {
      right: unset;
      left: -35px;
    }
  }
}