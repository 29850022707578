.completeWindow {
  .crown {
    animation: pulse-blue 2s 10s;
  }

  @keyframes pulse-blue {
    0% {
      transform: scale(1);
    }

    70% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
}