.videoPlayer {
  height: 100%;
  &:hover {
    cursor: pointer;
  }

  &.largeCaptions {
    video::cue {
      font-size: 22px;
    }
  }
}

.btnPlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%) translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 34px;
}