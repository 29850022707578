.pageLesson {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 10px 0;

  .header {
    z-index: 1;
    position: sticky;
    top: 0;

    padding: 10px 0;
    font-size: 1.4em;
    color: #bb8612;
    background: white;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .textArea {
    outline: none;
    > div[data-slate-node="element"] {
      line-height: 1.4;
      margin-bottom: 14px;
    }
  }

  .sentence {
    margin-right: 6px;

    &_active {
      // font-weight: 500;
      border-bottom: 1px dashed grey;
    }

    &_newLine {
      margin-left: 30px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dotted;
    }

    &_mobile:hover {
      cursor: default;
      text-decoration: none;
    }
  }

  .panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center ;
    background: #eef6ff;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1100px;
      padding: 10px 30px;

      @media (max-width: 600px) {
        padding: 10px 10px;
      }
    }

    .panel__placeholder {
      width: 80px;
    }

    .btn_next {
      background-color: green;

      &:hover {
        background-color: #7cb305;
      }
    }

    .btn_play {
      font-size: 32px;
      color: dodgerblue;

      &:hover {
        transform: scale(1.1);
      }
    }

    .record {
      &[data-recording="true"] {
        animation: pulse 1s infinite;
      }
    }
  }
}

.placeholder {
  height: 80px;
}

.tooltip {
  .dict {
    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px;
      padding-top: 5px;
    }
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(218, 18, 18);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
  }
}
