.dialogTaskOptions {

  .option {
    border: 1px dashed white;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    font-size: 19px;
    margin-right: 5px;
    margin-bottom: 10px;
    padding: 5px 10px;
    line-height: 1.4;
    color: #636363;

    background: #e4e4e4;

    &:hover {
      cursor: pointer;
      background: #bdbdbd;
      color: black;
    }

    &_left {
      color: white;
      background: rgba(113, 47, 47, 0.45);
      align-items: self-start;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;

      &:hover{
        background: rgba(55, 7, 7, 0.45);
        color: white;
      }
    }

    &_right {
      border-color: #239823;
      animation: greenPulse 1s;
      color: green;

      &:hover {
        color: green;
      }
    }

    &_wrong {
      border-color: #810000;
      animation: pulse 1s;
      color: #810000;

      &:hover {
        color: #810000;
      }
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }
}
