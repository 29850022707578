.pageMaskedElement {
  margin-right: 6px;
  padding: 5px;



  &_active {
    // font-weight: 500;
    border-bottom: 1px dashed lightgrey;
  }

  &_newLine {
    margin-left: 30px;
  }
}

.tooltip {
  .dict {
    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px;
      padding-top: 5px;
    }
  }
}

.div {
  padding: 5px 0;
  //border-bottom: 1px dashed lightgrey;
}
