.videoSentence {

  &__text {
    font-size: 19px;
    margin-right: 5px;
    line-height: 1.5;
    position: relative;

    &:hover {
      cursor: pointer;

      text-decoration: underline;
      text-decoration-color: lightgrey;
      text-decoration-style: dotted;
    }

    .btnPlay {
      position: absolute;
      left: -30px;
      top: 0
    }

    .btnSwitch {
      position: absolute;
      bottom: -2px;
      right: -25px
    }
  }

  &__inactive {
    color: grey;
  }

  &__textWrap {
    margin-left: 30px;
  }

  &__textActive {
    color: black;
    border-bottom: 1px dashed grey;

    &:hover {
      text-decoration: none;
    }
  }

  &__translate {
    color: grey;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 5px;
    padding-left: 16px;
  }

  .dict {

    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px ;
      padding: 5px;
      padding-top: 10px;
    }
  }
}
