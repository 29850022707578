.videoPlayerStudio {
  margin: 10px -20px;
  display: flex;
  justify-content: center;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  ::cue(u) {
    font-weight: normal;
    color: rgb(255, 200, 0);
  }

  .btnPlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%) translateY(-50%);
    color: rgba(255, 255, 255, 0.8);
    font-size: 48px;
  }
}