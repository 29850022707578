.recordButton {
  background-color: #930202 !important;

  &:hover {
    background-color: #d00303 !important;
  }

  &[data-recording="true"] {
    animation: pulse 1s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  &[data-speaking="true"] {
    animation: hithere 1s ease infinite;
  }

  @keyframes hithere {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    30% {
      transform: scale(1.2);
    }
    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }
    50% {
      transform: rotate(20deg) scale(1.2);
    }
    70% {
      transform: rotate(0deg) scale(1.2);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }
}
