.lessonsModule {
  position: relative;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  border-radius: 10px;
  background-size: cover;
  background-position: center 90%;
  // overflow: hidden;
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  z-index: 0;

  .ribbon__hidden {
    display: none;
  }

  .collapseTitle {
    font-weight: 500;
  }

  .collapseHeader {
    margin: 15px 0;
    text-align: center;
  }

  .collapseBody {
    padding: 15px 5px !important;
  }

  .title {
    font-size: 24px;
    font-weight: 500;
    padding: 15px;
    text-align: center;
  }

  &__completed {
   // opacity: 0.4;
  }

  .item {
    margin-left: 240px;
    min-height: 120px;

    .lesson {
      //display: grid;
      //grid-template-columns: 1fr 3fr;
      min-height: 80px;
      max-height: 300px;
      // padding: 10px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-style: dotted;
      }

      &_completed {
       opacity: 0.5;
      }

      &_locked {
        opacity: 0.5;
        pointer-events: none;
      }

      &__title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;
      }

      &__desc {
        font-size: 1.3em;
        line-height: 1.5;
        font-weight: normal;
      }
    }

    .coverWrapper {
      position: absolute;
      left: -140px;
      height: 100%;
      //top: 0;

      .cover {
        object-fit: cover;
        height: 100px;
        width: 100px;
        border-radius: 5px;
      }
    }
  }

  .progress {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .stars {
    //position: absolute;
    //left: 20px;
    //bottom: 5px;
    font-size: 14px;
  }

  &.mobile {
    grid-template-columns: 1fr;
    position: relative;

    .title {
      padding-top: 30px;
      font-size: 21px;
      line-height: 1.2;
    }

    .item {
      margin-left: 80px;

      .lesson {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;

        &__title {
          font-size: 18px;
          line-height: 1.1;
        }

        &__desc {
          font-size: 16px;
          margin: 5px 0;
        }
      }
      .coverWrapper {
        left: -100px;

        .cover {
          width: 60px;
          height: 60px;
        }
      }
    }

    .desc {
      font-size: 1em;
    }
  }

  .selected {
    text-decoration: underline;
  }
}

.popover__content {
  max-width: 600px;
  min-width: 260px;

  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-size: 1.2em;

  .btnStart {
    float: right;
  }

  .popover__title {
    // color: white;
    padding: 0 5px 5px 5px;
  }

  &__mobile {
    font-size: 1em;
  }
}
