.fade-enter {
    opacity: 0;
    /*transform: translateX(100%);*/
}

.fade-exit {
    opacity: 1;
    /*transform: translateX(0%);*/
}

.fade-enter-active {
    opacity: 1;
    /*transform: translateX(0%);*/
}

.fade-exit-active {
    opacity: 0;
    /*transform: translateX(-100%);*/
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms, transform 500ms;
}

.ant-drawer-header-title {
    flex-direction: row-reverse;
}

