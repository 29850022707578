.videoPlayer {
  height: 100%;

  &__black {
    background: black;
  }

  &:hover {
    cursor: pointer;
  }

  video::cue {
    visibility: hidden;
  }

  &__withCaptions {
    video::cue {
      visibility: visible;
    }
  }

  &.largeCaptions {
    video::cue {
      font-size: 22px;
    }
  }
}

.btnPlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%) translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 34px;
}