.sentenceLeaf {
  font-size: 20px;

  &_clickable {
    &:hover {
      cursor: pointer;
    }
  }

  &_wrong {
    text-decoration: line-through;
  }

  &_typo {
    color: #d00303;
  }

  &_form {
    // color: orange;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-style: wavy;
  }

  &_active {
    //text-decoration: underline;
    //text-decoration-style: dotted;

    border-bottom: 2px solid black !important;
  }

  &_placeholder {
    border-bottom: 2px solid lightgrey;
  }

  &_hidden {
    color: transparent;
    user-select: none;
  }

  &_underlined {
    text-decoration: underline;
    text-decoration-color: lightgrey;
  }

  &_masked {
    display: inline-block;
    min-width: 30px;
    border-bottom: 2px solid lightgrey;
    transition: min-width 2s;
  }

  &_missed {
    border: 1px solid red;
  }

  &_empty {
    border-bottom: 2px solid red !important;
  }

  &_order {
    border-bottom: 1px solid red;
  }

  &_green {
    border-bottom: 2px solid lightgreen;
  }

  &_hiddenMasked {
    border-color: transparent;
    transition: min-width 2s;
  }

  &_newLine {
   margin-left: 30px;
  }
}