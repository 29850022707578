.storySelectTask {
  line-height: 1.4;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  .image {
    float: right;
    max-width: 400px;
    // max-height: 300px;
    margin-left: 20px;

    .mask {
      background: unset;
    }

    &_center {
      float: unset;
      text-align: center;
    }
  }

  .sentences {
    display: grid;
    grid-gap: 10px;

    .sentence {


      font-size: 18px;

      .tags {
        .dropContent {
          display: inline-block;
          white-space: nowrap;
          border-bottom: 1px dashed grey;

          &:hover {
            cursor: pointer;
            border-bottom: 1px solid black;
          }

          &__empty {
            min-width: 50px;
            min-height: 26px;
            color: white;
          }

          &__wrong {
            color: red;
            border-bottom: 1px solid red;
          }
        }
      }

      .translate {
        margin-left: 20px;
        font-style: italic;
        color: grey;
      }
    }
  }

  &__mobile {
    .image {
      float: unset;
      margin-left: unset;
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  .btn_next {
    background-color: #7cb305;

    &:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
    }
  }

  .placeholder {
    height: 60px;
  }
}
