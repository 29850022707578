.leaf {
  font-size: 20px;

  &_wrong {
    text-decoration: line-through;
  }

  &_translate {
    color: grey;
    font-style: italic;
  }

  &_red {
    color: #d00303;
  }

  &_form {
    color: orange;
  }

  &_active {
    border-bottom: 1px solid black;
    //text-decoration: underline;
    //text-decoration-style: dotted;
  }

  &_bold {
    font-weight: bold;
  }

  &_missed {
    border: 1px solid red;
  }

  &_masked {
    display: inline-block;
    min-width: 40px;
    background: lightgray;
    //border-left: 10px solid lightgray;
    //border-right: 10px solid lightgray;
  }

  &_texted {
    background: unset;
  }

  &_newLine {
    margin-left: 30px;
  }
}