@font-face {
  font-family: 'Montserrat';
  src: url("./Fonts/Montserrat-ExtraLight.ttf") format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./Fonts/Montserrat-Light.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./Fonts/Montserrat-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./Fonts/Montserrat-Medium.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./Fonts/Montserrat-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

blockquote {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  border-left: 2px solid #ccc;
  padding-left: 16px;
  margin-right: 1.5rem;
  padding-right: 1rem;
  margin-left: 10px;
  line-height: 1.4;
}

table:not([cellpadding]) td,table:not([cellpadding]) th {
  padding: .4rem
}

table[border]:not([border="0"]):not([style*=border-width]) td,table[border]:not([border="0"]):not([style*=border-width]) th {
  border-width: 1px
}

table[border]:not([border="0"]):not([style*=border-style]) td,table[border]:not([border="0"]):not([style*=border-style]) th {
  border-style: solid
}

table[border]:not([border="0"]):not([style*=border-color]) td,table[border]:not([border="0"]):not([style*=border-color]) th {
  border-color: #ccc
}

figure {
  display: table;
  margin: 1rem auto
}

figure figcaption {
  color: #999;
  display: block;
  margin-top: .25rem;
  text-align: center
}

hr {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px 0 0 0
}

code {
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: .1rem .2rem
}

.voice::before, .audio::before {
  position: relative;
  left: -4px;
  top: 3px;
  background-image: url('https://lexoid.s3.eu-central-1.amazonaws.com/covers/sound.svg');
  background-size: 18px;
  opacity: 0.5;
  display: inline-block;
  width: 18px;
  height: 18px;
  content:" ";
}

.voice {
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: lightgrey;
}

.voice:hover,.audio:hover{cursor: pointer; text-decoration: underline; text-decoration-color: lightgrey;}
