.stories {
  //.grid {
  //  display: grid;
  //  grid-template-columns: repeat(4, 300px);
  //  grid-gap: 40px;
  //}

  .card {
    margin-bottom: 20px;
  }
}