.storyMatchTask {

  .button {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    padding: 10px 5px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-radius:5px;
    border: 1px solid lightgrey;

    &:hover:not(&__mobile) {
      cursor: pointer;
      color: dodgerblue;
      border: 1px solid dodgerblue;
    }

    //&__mobile:not(&__active) {
    //  &:hover {
    //    color: unset;
    //    border: 1px dashed lightgrey;
    //  }
    //}

    &__completed {
      border: none;
      pointer-events: none;
    }

    &__disabled {
      pointer-events: none;
    }

    &__active {
      color: dodgerblue;
      border: 1px solid dodgerblue;
    }


    &__correct {
      border-color: rgba(35, 152, 35, 0.42);
      animation: greenPulse 1s;
      color: green;
    }

    &__wrong {
      border-color: #810000;
      animation: pulse 1s;
      color: #810000;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgb(35, 152, 35);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
    }
  }

}

