.word {
  &:hover {
    cursor: pointer;

  }

  &_active {
    border-bottom: 2px solid black;
    //text-decoration: underline;
    //text-decoration-color: grey;
    //text-decoration-style: dotted;
  }
}