.MaskedDragDrop {
  height: 100%;

  .options {
    position: sticky;
    top: 0;

    min-height: 46px;
    padding: 10px;
    border-radius: 5px;
    background: rgb(160, 214, 253);
  }

  .tag {
    font-size: 18px;
    padding: 5px 10px;
    background: #fcf3f3;
    border-radius: 5px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;

    &__wrong {
      background: #ffb9b9;
    }
  }

  .sentences {
    margin: 20px 0;
    font-size: 16px;

    .sentence {
      display: flex;
      align-items: center;
      margin: 20px 0;
      gap: 10px;
    }

    .drop {
      display: inline-block;
      height: 36px;
      padding: 5px 5px;

      min-width: 60px;
      background: #fcf3f3;
      border-radius: 5px;
      border-bottom: 1px solid lightgrey;
     // margin-bottom: -3px;

      &__hovered {
        background: #e0dede;
        // min-width: 5px;

        .tag {
          background: #e0dede;
        }
      }

      &__filled {
        min-width: unset;
      }

      &__completed {
        background: unset;
        border: none;
        height: unset;
      }
    }
  }

  .btn_next {
    background-color: #7cb305;

    &:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
    }
  }
}
