.comments {
  &__date {
    font-weight: normal;
  }

  &__user {
    font-weight: normal;
  }

  &__text {
    color: black;
    font-size: 1.1em;
  }

  .newComment {
    display: grid;
    grid-template-columns: 46px 1fr;
  }

  .comment {
    padding: 0;
  }
}
