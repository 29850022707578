.storyDialogSent {

  &__hidden {
    visibility: hidden;
  }

  &__text {
    min-width: 50px;
    min-height: 38px;

    display: inline-block;
    border-radius: 10px;

    font-size: 19px;
    margin-right: 5px;
    padding: 5px 10px;
    line-height: 1.4;
    background: cornflowerblue;
    border-bottom-right-radius: 0;
    color: white;
    align-self: self-end;

    &:hover {
      cursor: pointer;

      text-decoration: underline;
      text-decoration-color: lightgrey;
      text-decoration-style: dotted;
    }

    &_isLeft {
      background: rgba(70, 0, 0, 0.45);
      align-items: self-start;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 10px;
    }
  }

  &__textWrap {
    margin-left: 30px;
  }

  &__showTranslate {
   text-decoration: underline;
    text-decoration-style: dotted;
  }

  &__textActive {
   //  color: black;
    // border-bottom: 1px dashed lightgrey;

    &:hover {
      text-decoration: none;
    }
  }

  &__translate {
    color: black;
  }

  .dict {

    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px ;
      padding: 5px;
      padding-top: 10px;
    }
  }
}

.word {

  &_active {
    border-bottom: 1px solid white;
    //text-decoration: underline;
    //text-decoration-color: grey;
    //text-decoration-style: dotted;
  }

  &_underlined {
    border-bottom: 2px solid white;

  }
}
