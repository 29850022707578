.lessonReview {
  //overflow: scroll;

  .tasks {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    overflow-x: scroll;
    padding: 40px 10px;

    .task {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      //min-height: 700px;
      height: calc(100vh - 160px);
      width: 400px;
      position: relative;
      border-radius: 15px;
      padding: 20px;
      box-shadow:
              0 1px 2px -2px rgba(0, 0, 0, 0.16),
              0 3px 6px 0 rgba(0, 0, 0, 0.12),
              0 5px 12px 4px rgba(0, 0, 0, 0.09);

      ::-webkit-scrollbar-thumb {
        background-color: unset;
        box-shadow: unset;
      }

      .lesson {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-x: scroll;
      }

      .title {
        font-size: 1.4em;
        margin-bottom: 10px;
      }

      .taskType {
        position: absolute;
        left: 10px;
        top: -24px;
        color: gray;

        &:hover {
          cursor: pointer;
        }
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;

        > div {
          width: 100%;

        }
        // min-height: calc(100% -  300px);
       // height: calc(100% - 10px);
        // padding: 0 10px;

        &__fullHeight {
          align-items: flex-start;
        }

        .childrenImage {
          margin: 0 -20px;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

}