.taskPanel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #eef6ff;
  display: flex;
  justify-content: center;
  align-items: center;

  &_ios {
    padding-bottom: 15px;
  }

  .content {
    width: 100%;
    max-width: 1100px;
    padding: 10px;

    @media (max-width: 1100px) {
      padding: 10px 30px;
    }

    @media (max-width: 600px) {
      padding: 5px;
    }
  }

  &_absolute {
    position: absolute;
  }

  .btn_back {
    color: gray;
  }

  .btn_next {
    background-color: #7cb305;

    &.completed {
      animation: greenPulse 1s;
    }

    &:hover {
      background-color: #92cb0d;
    }

    &.isMobile:hover {
      background-color: lightgrey;
    }

    &.completed:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
      pointer-events: none;
    }
  }

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgb(35, 152, 35);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
    }
  }
}