.sentenceTask {
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  @media (max-height: 700px) {
    .children {
      max-height: 150px !important;
    }

    .content {
      grid-gap: 10px !important;
    }
  }

  @media (max-height: 600px) {
    .children {
      max-height: 100px !important;
    }
  }

  .content {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-gap: 20px;
    justify-items: center;
    // align-items: center;
    justify-self: center;
    height: 100%;
    width: 100%;

    &_autoHeight {
      grid-template-rows: auto auto auto;
    }

    &_hasChildren .children {
      min-height: 300px;

      @media(max-height: 700px) {
        min-height: 150px;
      }

      @media(max-height: 600px) {
        min-height: 100px;
      }
    }

    .children {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      max-height: 300px;

      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .timeline {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;

      .hint {
        font-weight: 500;
      }

      &__dot {
        border-radius: 50%;
        color: green;
        font-size: 18px;
        animation: greenPulse 2s;
      }
    }

    .slate {
      width: 100%;

      &_spellerCollect {
        max-width: 350px;
      }

      &_alignCenter {
        text-align: center;

        .textArea {
          padding-right: 0 !important;
        }
      }

      .slate_wrapper {
        width: 100%;
        position: relative;
        // padding-left: 10px;

        .textArea {
          outline: none;
          border-bottom: 1px dashed grey;
          padding-right: 15px;
          font-weight: 500;
          line-height: 1.4;

          &.text {
            font-family: "Montserrat", Arial, Helvetica, sans-serif;
            font-size: 21px;
          }
        }

        .btn__clear {
          position: absolute;
          bottom: 5px;
          right: -10px;
          color: grey;
        }
      }

      .translate {
        font-size: 18px;
        margin: 10px 0;
        // padding-left: 10px;
        min-height: 22px;
      }
    }

    .answer {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      min-height: 44px;

      margin: 0;
    }

    .optionsWrapper {
      min-height: 90px;
      height: 100%;

      .options {
        display: flex;
        grid-gap: 5px;
        flex-wrap: wrap;
        justify-content: center;

        &__mobile {
          grid-gap: 10px;
        }
      }
    }

    .cursor {
      display: inline-block;
      width: 1px;
      height: 28px;
      margin: 2px;
      background: black;
      animation: virtualCursor 1s infinite;
    }

    @keyframes virtualCursor {
      to {
        background: unset;
      }
    }

    .tag {
      font-family: "Montserrat", Arial, Helvetica, sans-serif;
      font-size: 21px;
      line-height: 1.4;
      margin: 1.5px;
      padding: 2px 5px;
      background: #f6f6f6;
      border-radius: 5px;

      &.letter {
        //font-size: 32px;
        padding: 2px 10px;
        font-weight: 500;
      }

      &__mobile {
        font-size: 26px;
      }

      &__mobile.letter {
        font-size: 32px;
      }

      &__wrong {
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-color: red;
      }

      &__merged {
        min-width: 15px;
        margin-left: -5px;
        padding-left: 0;
      }

      &__selected {
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: gray;
      }
    }

    .bottom {
      //display: grid;
      //justify-content: center;
      //align-items: center;
      height: 100%;

      .icon_completed {
        font-size: 64px;
        color: #7cb305;
      }

      .select {
        justify-content: center;
        //margin: 0 -10px;
      }
    }
  }

  .grammar {
    font-size: 1.3em;
    margin-bottom: 80px;

    table {
      //  border-collapse: collapse;
      thead {
        background: #bce4ff;
      }
      tbody {
        th,
        td {
          padding: 5px;
          // border: 2px solid gray;
        }

        tr:nth-child(even) {
          background-color: #f2f2f2;
        }
      }

      p {
        margin: 0 5px;
      }
    }

    img {
      vertical-align: middle;
      width: 24px;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    blockquote {
      p {
        margin: 5px;
      }
    }

    &__mobile {
      table {
        width: 100% !important;
        font-size: 15px;
      }
      font-size: 1.1em;

      blockquote {
        display: grid;
        grid-gap: 10px;
        align-items: center;
        margin: 0;
        margin-right: -10px;
      }
    }
  }

  .pictures {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-auto-rows: auto;
    //grid-auto-flow: dense;
    gap: 10px;

    .picture {
      display: flex;
      justify-content: center;
      padding: 10px;

      .img {
        max-height: 160px;
        width: auto;
        max-width: 100%;
      }
    }

    &_mobile {
      grid-template-columns: repeat(2, 1fr);

      .picture {
        padding: 0;
      }
    }
  }

  .record {
    background-color: #930202;

    &:hover {
      background-color: #d00303 !important;
    }

    &[data-recording="true"] {
      animation: pulse 1s infinite;
    }
  }

  .buttons {
    position: relative;

    .btn_skip {
      position: absolute;
      right: -60px;
      top: 18px;
    }
  }

  .voiceTags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 2px solid transparent;

    .voiceTag {
      font-size: 18px;
      margin-left: 5px;
      color: transparent;
      border-bottom: 2px solid white;

      &__hint {
        color: #333333;
      }

      &__merged {
        margin-left: unset;
      }

      &__active {
        border-bottom: 2px solid grey;
      }

      &__completed {
        color: black;
      }
    }
  }

  .btnSelect {
    &:hover {
      cursor: pointer;
      border: 1px solid black;
    }
    font-size: 21px;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    padding: 10px 15px;
    text-align: center;

    &__selected {
      border: 1px solid black;
    }
  }

  .btn_play {
    // font-size: 32px;
    color: dodgerblue;

    &:hover {
      transform: scale(1.1);
    }
  }

  .btn_next {
    background-color: #7cb305;

    &:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
    }
  }

  .btn_green {
    border-color: #239823 !important;
    animation: greenPulse 1s;
    color: green;

    &:hover {
      color: green !important;
    }
  }

  .btn_bold {
    border-color: #33a3e8 !important;
    color: #33a3e8;
    animation: unset;
  }

  .btn_disabled {
    pointer-events: none;
  }

  .btn_red {
    border-color: #810000 !important;
    animation: pulse 1s;
    color: #810000;

    &:hover {
      color: #810000 !important;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgb(35, 152, 35);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
    }
  }

  .placeholder {
    height: 60px;
  }
}

.intro {
  font-size: 1.6em;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  &__mobile {
    font-size: 1.2em;
  }

  &__title {
    margin-bottom: 10px;
  }
}
