.App {
  min-height: 100vh;
}

.mobile .ant-modal-confirm-btns {
  display: grid;
  grid-gap: 20px;
}

.mobile .ant-modal-confirm-btns button  {
    margin-inline-start: 0 !important;
}

.mobile .ant-dropdown-menu-item {
    margin: 10px !important;
}

.mobile {
    video::cue {
        font-size: 22px;
    }
}