.maskedLeaf {
  font-size: 20px;

  &_wrong {
    text-decoration: line-through;
  }

  &_typo {
    color: #d00303;
  }

  &_form {
    // color: orange;
    text-decoration: underline;
    text-decoration-color: red;
    text-decoration-style: wavy;
  }

  &_active {
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  &_completed {
    border-bottom: 1px solid grey;
  }

  &_empty {
    border-bottom: 1px solid red !important;
  }

  &_order {
    border-bottom: 1px solid red;
  }

  &_masked {
    display: inline-block;
    min-width: 30px;
    border-bottom: 1px solid lightgrey;
    //border-left: 10px solid lightgray;
    //border-right: 10px solid lightgray;
  }

  &_translate {
    color: grey;
    font-style: italic;
  }

  &_hint {
    border-bottom: 1px solid lawngreen;

  }

  &_newLine {
   margin-left: 30px;
  }
}