.dialogTaskTranslate {
  .message {
    &__error {
      .text {
        animation: ellipsis 1000ms infinite;
      }
    }

    .text {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      font-size: 20px;

      width: 100%;
      margin-top: 2px;
      padding: 5px 10px 5px 15px;
      color: white;
      background: cornflowerblue;
      border-radius: 10px;
      border-bottom-right-radius: 0;

      .tag {
        margin-left: 5px;
        color: transparent;

        &__hint {
          color: wheat;
        }

        &__merged {
          margin-left: unset;
        }

        &__active {
          border-bottom: 1px solid white;
        }

        &__completed {
          color: white;
        }
      }
    }

    &__left {
      .text {
        background: rgba(70, 0, 0, 0.45);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 10px;
      }
    }

    .translate {
      color: black;
      padding: 0 5px;
    }

    .bulb {
    }

    &__completed {
      padding-right: 10px;
    }

    .textArea {
      outline: none;
      //  border-bottom: 1px dashed lightgrey;
      &.text {
        font-family: "Montserrat", Arial, Helvetica, sans-serif;
        font-size: 21px;
      }
    }

    .btnHint {
      position: absolute;
      bottom: 3px;
      right: 0;
      color: white;
    }
  }

  @keyframes ellipsis {
    to {
      background: brown;
    }
  }
}
