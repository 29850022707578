.scrollerTask {
  margin: 0 auto;
  height: 100%;

  .header {
    position: sticky;
    top: 0;

    padding: 10px 0;
    font-size: 1.4em;
    color: #bb8612;
    background: white;
  }

  .content {
    display: grid;
    align-items: center;
    height: calc(100vh - 150px);

    &__fullHeight {
      height: unset;
    }
  }

  .btn_green {
    border-color: #239823 !important;
    animation: greenPulse 1s;
    color: green;

    &:hover {
      color: green !important;
    }
  }

  .btn_next {
    background-color: #7cb305;

    &:hover {
      background-color: #92cb0d !important;
    }

    &:disabled {
      background-color: lightgrey;
    }
  }

  .btn_record {
    background-color: #930202;

    &:hover {
      background-color: #d00303 !important;
    }

    &[data-recording="true"] {
      animation: pulse 1s infinite;
    }
  }

  .btn_red {
    border-color: #810000 !important;
    animation: pulse 1s;
    color: #810000;

    &:hover {
      color: #810000 !important;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }
}
