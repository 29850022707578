.multiSelectTask {
  width: 100%;

  &__completed {
    pointer-events: none;
  }

  .option {
    padding: 5px 12px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    border: 1px solid lightgrey;
    border-radius: 6px;

    &:hover {
      border: 1px solid grey;
    }

    &__mobile {
      width: 100%;
      padding: 5px 10px;
      font-size: 1.3em;
      line-height: 1.2;
    }

    &__selected {
      border: 1px solid black;
      padding: 5px 11px;
      font-weight: 500;
    }

    &__right {
      border: 1px solid green;
      font-weight: 500;
      color: #239823;
    }

    &__wrong {
      color: lightgrey;
    }
  }
}