.mainLayout {
  min-height: 100vh;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      padding: 0 10px;
    }

    &__container {
      max-width: 1100px;
      width: 100%;
      display: grid;
      grid-template-areas: "vocabulary user";

      .user {
        grid-area: user;
        display: flex;
        align-items: center;
        justify-self: flex-end;
        font-size: 16px;

        .user_name {
          margin: 0 10px;
          line-height: 18px;
          white-space: nowrap;

          // color: white;
          // font-weight: bold;
        }
      }

      .vocabulary {
       font-size: 28px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
          text-decoration-style: dotted;
        }
      }

      &_mobile {
        grid-template-areas: "user vocabulary";

        .user {
          justify-self: flex-start;
          font-size: 14px;
        }

        .vocabulary {
         // justify-self: flex-end;
        }
      }

      .header__menuIcon {
        visibility: hidden;

        @media (max-width: 890px) {
          visibility: visible;
        }
      }

      .header__links {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 30px;
      }
    }
  }

  .login {
    line-height: normal;
  }

  .container {
    display: flex;
    justify-content: center;

    .content {
      width: 100%;
      max-width: 1100px;

      background: white;
      margin: 20px 30px;
      padding: 20px 50px;

      @media (max-width: 600px) {
        margin: 20px 0;
        padding: 20px;
      }

      &__withoutHeader {
        margin: unset;
      }
    }
  }

  &_fullWidth {
    .container {
      .content {
        max-width: unset;
       padding: 20px;
      }
    }
  }
}

.menu {
  @media (max-width: 890px) {
    display: none;
    visibility: hidden;
  }
}
