.taskPanel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #eef6ff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.review {
    position: absolute;
  }

  &_ios {
    padding-bottom: 15px;
  }


  .content {
    width: 100%;
    max-width: 1100px;
    padding: 10px;

    @media (max-width: 1100px) {
      padding: 10px 30px;
    }

    @media (max-width: 600px) {
      padding: 5px;
    }
  }
}