.book {
  width: 100%;
  // display: grid;
  // grid-template-rows: 20px 40px minmax(400px, 100%) 60px;
  height: 100%;
  position: relative;

  .source {
    margin: 15px 0;
  }

  .header {
    position: sticky;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    top: 10px;
    background-color: white;
  }

  .doublePage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // padding: 10px 30px;
    background: #eef6ff;
    display: flex;
    justify-content: center ;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1100px;
      padding: 10px 30px;

      @media (max-width: 600px) {
        padding: 10px 10px;
      }
    }

    .panel__placeholder {
      width: 80px;
    }
  }
}
