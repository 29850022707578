.grammarPanel {
  font-size: 1.2em;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  img {
    vertical-align: middle;
    width: 24px;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  blockquote {
    p {
      margin: 5px;
    }
  }

  &__mobile {
    font-size: 1.2em;

    blockquote {
      display: grid;
      grid-gap: 10px;
      align-items: center;
      margin: 0;
      margin-right: -10px;
    }
  }
}