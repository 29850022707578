.pushAudioRecorder {
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;

  background-color: dimgrey;

  &:hover {
    cursor: pointer;
    background-color: grey;
  }

  &:active {
    transform: translateY(2px);
  }

  &[data-recording="true"] {
    background-color: #930202;
    animation: pulse 1s infinite;
  }

  &[data-playing="true"] {
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }

  &[data-speaking="true"] {
    animation: hithere 1s ease infinite;
  }

  @keyframes hithere {
    0% {
      box-shadow: 0 0 0 0 rgb(218, 18, 18);
    }
    30% {
      transform: scale(1.2);
    }
    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }
    50% {
      transform: rotate(20deg) scale(1.2);
    }
    70% {
      transform: rotate(0deg) scale(1.2);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(40, 167, 69, 0);
    }
  }
}
