.MaskedDragDrop {
  width: 100%;

  .tags {
    z-index: 1;
    position: sticky;
    top: 0;
    min-height: 44px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background: rgb(160, 214, 253);
    padding-right: 30px;
  }

  .tooltip {
    position: absolute;
    right: 4px;
    top: 14px
  }

  .image {
    float: right;
    margin-top: 20px;
    max-width: 400px;
    margin-left: 20px;

    &_center {
      float: unset;
      text-align: center;
    }
  }

  .mask {
    background: unset;
  }


  .sentences {
    margin: 20px 0;
    width: 100%;
  }

  .droppableSentence {
    font-size: 16px;
    min-width: 200px;
    min-height: 36px;
    padding: 5px 10px;
    color: gray;

    border: 1px dotted lightgrey;
    border-radius: 5px;

    &__wrong {
      border: 1px solid red;
      background: rgba(208, 3, 3, 0.12);
    }

    &__hover:hover {
      cursor: pointer;
      background: #fde3e3;
    }

    &__hovered {
      background: rgba(218, 218, 218, 0.15);
    }

    &__filled {
      min-width: unset;
    }
  }

  &_mobile {
    .image {
      margin-left: unset;
      float: unset;
      margin-bottom: 10px;
    }
  }
}

.tag {
  font-size: 18px;
  padding: 5px 10px;
  background: #fcf3f3;
  border-radius: 5px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: black;

  &__selected {
    background: #fde3e3;
    // transform: translateY(-5px);
  }

  &__nonSelected {
    opacity: 0.5;
  }
}
