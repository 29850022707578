.lesson {
  margin: 0 auto;
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;

  .mask {background: unset}

  .header {
    padding: 10px 0;
    background: white;
    z-index: 10;
  }

  .title {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 1.4em;
    margin-bottom: 20px;

    p {
      margin: 10px 0;
    }
  }

  .contentWrapper {
    flex: 1;

    display: flex;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;

    &__fullHeight {
      justify-content: unset;
    }
  }

  .video {
    margin: 10px -20px;
    display: flex;
    justify-content: center;
  }

  &__isMobile {
    min-height: calc(100vh - 90px);

    .header {
      //position: sticky;
      //top: 0;
    }

    .title {
      font-size: 1.1em;
      margin-bottom: 10px;
    }

    .childrenImage {
      margin: 10px -20px;
    }
  }
}

.tooltip {
  border-bottom: 2px dotted grey;
  &:hover {
    cursor: pointer;
    border-bottom: 2px dotted black;

  }
}

.btn_next {
  background-color: #7cb305 !important;

  &:hover {
    background-color: #92cb0d !important;
  }

  &:disabled {
    background-color: lightgrey;
  }
}


