.dictionary {
  font-style: italic;
  li {
    padding: 5px !important;
  }

  &__word {
    font-size: 18px;
    margin-right: 10px;
    color: black;
    font-weight: 600;
  }

  &__translate {
    font-size: 16px;
    font-weight: 400;
    color: black;
  }
}