.courses {
  &__header {
    font-size: 18px;
    font-weight: 500;
  }

  .list {
    display: grid;
    grid-gap: 20px;
    margin: 20px 0;
  }

  &__cards {
    margin: 0 -10px;
    padding: 5px 10px 20px 10px;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    grid-gap: 20px;
    overflow-x: scroll;
  }

  .card {
    position: relative;
    padding: 10px 15px;
    height: 150px;
    width: 150px;
    // border: 1px dashed lightgrey;
    border-radius: 10px;
    border: none;
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);

    &:hover {
      cursor: pointer;
      transform: scale(1.02);
    }

    &__locked {
      pointer-events: none;

      > div > div {
        opacity: .5;

      }
    }

    .ribbon__hidden {
      display: none;
    }

    &_books {
      height: 200px;
      width: 180px;
      border: none;
      background-size: cover;
    }

    &_courses {
      width: 100%;
      background-size: cover;
      height: unset;
      min-height: 160px;
    }

    &_videos {
      width: unset;
      height: unset;
      padding: 0;

      > div {
        border-radius: 10px;
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-gap: 40px;
      height: 100%;
      margin-bottom: 10px;
      font-family: "Montserrat", Arial, Helvetica, sans-serif;

      .content__right {
        position: relative;
        height: 100%;
      }

      .picture {
        margin: -10px;
        margin-left: -15px;
        border-radius: 5px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;

        background-size: cover;
        background-position: center;
      }

      .courseProgress {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    &_mobile {
      .content {
        margin: 10px 0;
        display: block;

        .picture {
          display: none;
        }
      }
    }

    &__progress {
      position: absolute;
      right: 5px;
      bottom: 5px;
      background-color: white;
      border-radius: 50%;
    }

    &__title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin-top: 5px;
    }

    &__description {
      color: grey;
      font-style: italic;
      font-size: 16px;
    }
  }
}

.layout {
  min-height: 100vh !important;
}

.steps {
  margin-bottom: 20px;
}

.siteLayoutContent {
  min-height: calc(100vh - 160px);
  padding: 24px;
  background: #fff;
}

.task {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .task_text {
    margin: 40px;
    max-width: 800px;

    font-size: 26px;
    font-weight: bold;
  }
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
