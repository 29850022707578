.VideoDragDrop {
  width: 100%;

  &.mobile {
    .cards {
      grid-template-columns: 1fr;
    }
  }

  .texts {
    z-index: 10;
    position: sticky;
    top: 0;
    min-height: 44px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    background: rgb(160, 214, 253);
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 70px;

    .droppableCard {
      margin-bottom: 10px;
      font-size: 16px;
      color: gray;

      border-radius: 5px;

      &__wrong {
        border: 1px solid red;
        background: rgba(208, 3, 3, 0.12);
      }

      &__hover:hover {
        cursor: pointer;
        background: #e1e0e0;
      }

      &__hovered {
        background: lightgrey;

      }

      &__filled {
        min-width: unset;
      }

      .dropZone {
        min-height: 40px;
       // background: #fffadf;
        border-radius: 5px;
        border: 2px dotted lightgrey;
        padding-top: 7px;
        margin-bottom: -2px;

        &__filled {
          border: 2px solid lightgrey;
          background: unset;
        }
      }
    }
  }
}

.tag {
  z-index: 999;
  line-height: 1.4;
  font-size: 18px;
  padding: 5px 10px;
  background: #fcf3f3;
  border-radius: 5px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  color: black;

  &__selected {
    background: #fde3e3;
    // transform: translateY(-5px);
  }

  &__nonSelected {
    opacity: 0.5;
  }
}

.btnNext {
  background-color: #7cb305;

  &:hover {
    background-color: #92cb0d !important;
  }
  animation: greenPulse 1s;
  // color: green;
}

@keyframes greenPulse {
  0% {
    box-shadow: 0 0 0 0 rgb(35, 152, 35);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(35, 152, 35, 0);
  }
}
