.animatedAlien {
  margin: 0 auto;
  max-width: 300px;

  .cls-1 {
    fill: #9cf;
  }
  .cls-2 {
    fill: #898890;
  }
  .cls-3 {
    fill: #75747a;
  }

  &__loading {
    animation: svg-shadow 1s ease-in-out infinite alternate;
  }

  @keyframes svg-shadow {
    from {
      filter: drop-shadow( 0 0 5px #ff8989) drop-shadow( 0 0 5px #ff8989) drop-shadow( 0 0 5px #ff8989);
    }

    to {

      filter: drop-shadow( 0 0 10px #ff3561) drop-shadow( 0 0 20px #ff3561) drop-shadow( 0 0 30px #ff3561);
    }
  }
}