.page {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 10px 0;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .image {
    margin: 30px 0;
  }

  &__text {
    margin: 20px;

    @media (max-width: 600px) {
      margin: 0;
      margin-top: 10px;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textArea {
    > div[data-slate-node="element"] {
      line-height: 1.4;
      margin-bottom: 14px;
    }
  }

  .element__sentence {
    margin-right: 6px;

    &_active {
      // font-weight: 500;
      border-bottom: 1px dashed grey;
    }

    &_newLine {
      margin-left: 30px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  .placeholder {
    height: 80px;
  }
}

.tooltip {
  .dict {
    &__row {
      border-top: 1px dashed lightgrey;

      margin: 5px;
      padding: 5px;
      padding-top: 10px;
    }
  }
}
