.SentenceDragDrop {
  line-height: 2;
  margin: 0 5px;
  font-size: 18px;

  &__asBlock {
    display: block;
  }

  .drop {
    display: inline-block;
    height: 28px;
    min-width: 60px;
    background: #fcf3f3;
    border-radius: 5px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: -3px;

    &__hover:hover {
      cursor: pointer;
      background: rgba(218, 218, 218, 0.15);
    }

    &__hovered {
      background: #e0dede;
      // min-width: 5px;
    }

    &__filled {
      min-width: unset;
    }

    &__completed {
      background: unset;
      border: none;
      height: unset;
    }
  }

  .tag {
    font-size: 18px;
    padding: 2px 5px;
    background: #fcf3f3;
    border-radius: 5px;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;

    &__selected {
      background: #fde3e3;
    }

    &__wrong {
      border: 1px solid red;
    }
  }

  .translate {
    margin-left: 15px;
    color: grey;
  }
}