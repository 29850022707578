.hintHtml {
  font-size: 18px;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;

  * {
    margin: 0;
  }

  .tooltip {
    border-bottom: 2px dotted grey;
    &:hover {
      cursor: pointer;
      border-bottom: 2px dotted black;

    }
  }

}