.videoPage {
  height: calc(100vh - 150px);
  display: grid;
  // grid-template-rows: 1fr 1fr;

  .player {
    margin: -20px -50px;
    margin-bottom: 0;
    position: relative;

    .btnClose {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &_mobile {
    height: calc(100vh - 40px);
    grid-template-rows: 3f  1fr;

    .player {
      // height: 300px;
      margin: -20px;
      margin-bottom: 0;
    }
  }

  .skeleton {
    height: 600px;
    overflow: hidden;
  }


  .transcript {
    overflow-y: scroll;
  }
}